import { ILibraryContent } from "./content";
import { Organisation } from "./organisation";

export class User {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  img: string = "";
  organisations: Array<Organisation> = [];
  hasAcceptedTos: boolean;
  isSocialUser: boolean = false;
  arixSchoolid?: string;

  constructor(data: Record<string, unknown>) {
    this.id = Number(data.id);
    this.email = String(data.email);
    this.firstName = String(data.firstName);
    this.lastName = String(data.lastName);
    this.hasAcceptedTos = Boolean(data.hasAcceptedTos);
    this.isSocialUser = Boolean(data.isSocialUser);
    this.arixSchoolid = String(data.arixSchoolid);

    if (Array.isArray(data.organisations)) {
      data.organisations.forEach((entry: Record<string, unknown>) => this.organisations.push(new Organisation(entry)));
    }
  }

  /**
   * Return the first organisation of the user.
   *
   */
  public get firstOrganisation(): Organisation | null {
    if (this.organisations.length > 0) {
      return this.organisations[0];
    } else {
      return null;
    }
  }

  /**
   * Check whether the user is one of the owner of the content.
   *
   * @param content
   * @returns
   */
  public isOwnerOfContent(content: ILibraryContent): boolean {
    const index = this.organisations.findIndex(organisation => organisation.id === Object(content.owner)?.id);
    return Boolean(index >= 0);
  }

  /**
   * Checks if the user is an administrator of the current selected organisation
   */
  public isAdministratorOfCurrentOrganisation(): boolean {
    for (let i = 0; i < (this.firstOrganisation?.memberships?.length || 0); i++) {
      const membership = this.firstOrganisation?.memberships[i];
      if (membership?.isAdministrator && membership.user.email === this.email) {
        return true;
      } else {
      }
    }
    return false;
  }
}

export interface IUserPatch {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
}

export interface IUserUrlHash {
  email: string;
  organisation_id: number;
}
