import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Text,
  Button,
  Flex,
  Checkbox,
  Divider,
  useDisclosure,
  Container,
  useToast,
  Stack
} from "@chakra-ui/react";
import { ExternalLinkIcon } from "@heroicons/react/solid";

import { useEffect, useRef, useState } from "react";
import { IS_PRODUCTION } from "../../constants";

import { useAuth } from "../../provider/authentication";
import { ApiUserService, ICurrentToS } from "../../services/http";

export default function TermsOfServiceModal() {
  const toast = useToast();
  const cancelRef = useRef<any>();
  const tosModal = useDisclosure();
  const [isToSUpdate, setIsToSUpdate] = useState(false);
  const { isOpen: tosIsOpen, onOpen: tosOnOpen, onClose: tosOnClose } = tosModal;
  const { user, refreshUser, isAuthenticated, doLogout, doLogoutToSDenial } = useAuth();

  const [currentToSObject, setCurrentToSObject] = useState<ICurrentToS>();

  function openToSDocument() {
    window.open(currentToSObject?.tosFile, "_blank");
  }
  function openDataSecurityDocument() {
    window.open("legals/VIL_Datenschutzinformation_Kunden+Interessenten_V3.pdf", "_blank");
  }
  const [tosIChecked, setToSIsChecked] = useState(false);

  const onSubmit = () => {
    tosOnClose();
    ApiUserService.acceptToS().then(res => {
      if (res.status === 201) {
        refreshUser();
        toast({
          status: "success",
          title: "Nutzungsbedingungen aktzeptiert"
        });
      } else {
        toast({
          status: "error",
          title: "Unbekannter Fehler",
          description: "Bitte probieren Sie es später erneut oder melden "
        });
        doLogout();
      }
    });
  };

  const handleAbortModal = () => {
    tosOnClose();
    doLogoutToSDenial();
  };

  useEffect(() => {
    if (isAuthenticated && !currentToSObject && tosIsOpen) {
      ApiUserService.getMyToS().then(res => {
        if (res.status === 200) {
          setCurrentToSObject(res.data);
        }
      });
    }
  }, [tosIsOpen, isAuthenticated, currentToSObject]);

  useEffect(() => {
    if (isAuthenticated) {
      if (user && !user.hasAcceptedTos && IS_PRODUCTION) {
        tosOnOpen();
      }
    }
  }, [user, isAuthenticated]);

  return (
    <Modal isCentered closeOnOverlayClick={false} closeOnEsc={false} isOpen={tosIsOpen} onClose={handleAbortModal}>
      <ModalOverlay>
        <ModalContent>
          <ModalHeader color={"darkBlue.500"} fontSize={"lg"} fontFamily="Bitter" fontWeight={600}>
            {isToSUpdate ? "Aktualisierte" : ""} Datenschutz & Nutzungsbedingungen
          </ModalHeader>

          <ModalBody>
            <Text>
              Bitte akzeptieren Sie zur {isToSUpdate ? "weiteren" : ""} Nutzung des VIL Portal unsere{" "}
              {!isToSUpdate ? "aktuellen" : "aktualisierten"} Datenschutzbestimmungen & Nutzungsbedingungen.
            </Text>
            <Container my={4}>
              <Stack gap={4}>
                <Button
                  w={"full"}
                  rightIcon={<ExternalLinkIcon className="w-5 h-5" />}
                  variant={"solid"}
                  onClick={() => openDataSecurityDocument()}
                >
                  Datenschutzbestimmungen
                </Button>

                <Button
                  w={"full"}
                  rightIcon={<ExternalLinkIcon className="w-5 h-5" />}
                  variant={"solid"}
                  onClick={() => openToSDocument()}
                >
                  Nutzungsbedingungen
                </Button>
              </Stack>
            </Container>

            <Checkbox
              mt={2}
              onChange={() => {
                setToSIsChecked(!tosIChecked);
              }}
            >
              <Text>Ich habe die Nutzungsbedingungen gelesen und aktzeptiere sie.</Text>
            </Checkbox>

            <Divider my={4} />
          </ModalBody>

          <ModalFooter>
            <Flex w="full" justifyContent={"space-between"}>
              <Button ref={cancelRef} onClick={handleAbortModal} variant="ghost">
                Abbrechen
              </Button>
              <Button isDisabled={!tosIChecked} onClick={onSubmit} ml={3}>
                Aktzeptieren
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
}
